exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)

// smoothly scroll to #element, or to #main on initial page load
// NOTE: on development environment, won't work because elements aren't static
function scrollToAnchor(location, mainNavHeight = 0) {
    const hash = (location && location.hash) ? location.hash : "#main"
    const element = document.querySelector(`${hash}`)
    window.scrollTo({
        top: element.offsetTop - mainNavHeight,
        behavior: "auto"
    })
    document.querySelector('html').style.scrollBehavior = "smooth"
    document.querySelector('body').style.scrollBehavior = "smooth"

    return true
}

/*

const setupMainPage = () => {
    const getMainElement = () => document.getElementById("main")
    const focusElement = ((window.location.hash.length === 0) ?
            getMainElement() : (document.getElementById(window.location.hash.substring(1)) || getMainElement()))

    //FIXME: don't show page load header title + menu animation

    focusElement.scrollIntoView({ block: "start", inline: "nearest", behavior: "auto" })
    document.querySelector('html').style.scrollBehavior = "smooth"
    document.querySelector('body').style.scrollBehavior = "smooth"
}

*/